import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class GlobalService {

    subject = new BehaviorSubject(false);

    public title = "";
    public CompanyName: string = "";
    public CompanyID: number = 0;
    public LocationID: number = 0;
    public MultiLocation: boolean = false;
    public ComponentID: number = 0;
    public UserID: number = 0;
    public EmployeeID: number = 0;
    public EmployeeName: string = "";
    public ClicktocallUserID: string = "";
    public ClicktocallUserPass: string = "";
    public LoginTemplate: string = "";
    public UserName: string = "";
    public LoginTemplateName: string = "";
    public MenuComponentAuthentication = [];
    public VisibleComponentMenu = {};
    public VisibleComponentMenuID = {};
    public OutOfTatUser: boolean = false;
    public EmployeeImagePath = "";
    public UserBucketList = "";
    public EmployeeDesignation = "";
    public UserBucketListArr = [];

    public CasedetailsCommonMasterList = [];
    public ProcessingCommonMasterList = [];
    public AllocationCommonMasterList = [];
    public FindingsCommonMasterList = [];
    public ClosureCommonMasterList = [];
    public VigilanceCommonMasterList = [];
    public VICommonMasterList = [];
    public FOBillingCommonMasterList = [];
    public NetworkingCommonMasterList = [];
    public HordCopyCommonMasterList = [];
    public EmployeeCommonMasterList = [];

    

    public IsAppData: boolean = false;
    public today;
    public DateForComparing;
    public CurrentDateTime;
    public CurrentLocalDateTime;
    public TimeDifference;
    public CurrentDateTimeWithoutChanges;
    public SelectedPreviousDateTime;
    public PreviousDateTransaction = false;
    public FromDate: string = "";
    public ToDate: string = "";
    public LastVisitedComponent: string = "";
    public GSM: string = "";
    public Gender: string = "";
    public DOB: string = "";
    public GSM1: string = "";
    public PostalCode: string = "";
    public City: string = "";
    public Address: string = "";
    public IsPasswordCorrect: boolean = false;
    public ExpireTime: Date;
    public IsClientSuperAdmin: boolean = false; // Is Client Super Admin
    public IsSuperUser: boolean = false;
    public ISAdditionaBillApproval: boolean = false;
    messages: any = {};
    configurations: any = {};

    configurationsDetails = [];
    mstconfigurations: any = {};
    CaseDetailsID: number = 0;
    InvestigationID: number = 0;
    PolicyNumber: string = "";
    VerticalName: string = "";
    InsuredState: string = "";
    ClaimName: string = "";
    ClientID: number = 0;
    BranchID: number = 0;
    ClaimNo: string = "";
    BillID: number = 0;
    ProcessingPlatformVisible: boolean = false;
    VirtualInvestigationVisible: boolean = false;
    BulkCaseUploadVisible: boolean = false;
    RulingAuditVisible: boolean = false;
    MediaAuditVisible: boolean = false;
    MedicalAuditVisible: boolean = false;
    DesktopAuditVisible: boolean = false;
    AllocationVisible: boolean = false;
    NetworkingVisible: boolean = false;
    ClientBillVisible: boolean = false;
    VideoConferenceVisible: boolean = false;
    InvoiceExcelUploadVisible: boolean = false;
    InternalChatVisible: boolean = false;
    HistoricalDataVisible: boolean = false;
    FindingsVisible: boolean = false;
    HardCopyVisible: boolean = false;
    BillReconciliationVisible: boolean = false;
    ClosureVisible: boolean = false;
    VigilanceVisible: boolean = false;
    FoPaymentVisible: boolean = false;
    FoBillingVisible: boolean = false;
    FoFindingsVisible: boolean = false;
    commonReportVisible: boolean = false;
    userReportVisible: boolean = false;
    auditReportVisible: boolean = false;
    accountReportVisible: boolean = false;
    venderBillingVisible: boolean = false;
    public FOBill: number = 0;
    public FOPayment: number = 0;
    public ClientBill: number = 0;
    public ClientPayment: number = 0;
    public VendorBill: number = 0;
    public VendorPayment: number = 0;

    LatLongList = [];
    NewVisitLatLong = "";

    constructor(private toastrService: ToastrService) { }

    // Setting Default Values For Global Service
    SetDefaultValuesForGlobal() {
        this.title = "";
        this.CompanyName = "";
        this.ComponentID = 0;
        this.CompanyID = 0;
        this.LocationID = 0;
        this.IsAppData = false;
        this.UserID = 0;
        this.EmployeeID = 0;
        this.EmployeeName = "";
        this.ClicktocallUserID = "";
        this.ClicktocallUserPass = "";
        this.LoginTemplate = "";
        this.UserName = "";
        this.LoginTemplateName = "";
        this.MenuComponentAuthentication = [];
        this.VisibleComponentMenu = {};
        this.VisibleComponentMenuID = {};
        this.today = "";
        this.DateForComparing = "";
        this.CurrentDateTime = "";
        this.CurrentDateTimeWithoutChanges = "";
        this.SelectedPreviousDateTime = "";
        this.PreviousDateTransaction = false;
        this.MultiLocation = false;
        this.FromDate = "";
        this.ToDate = "";
        this.LastVisitedComponent = "";
        this.GSM = "";
        this.Gender = "";
        this.DOB = "";
        this.GSM1 = "";
        this.PostalCode = "";
        this.City = "";
        this.Address = "";
        this.IsPasswordCorrect = false;
        this.ExpireTime = new Date();
        this.IsClientSuperAdmin = false; // Is Client Super Admin
        this.IsSuperUser = false;
        this.ISAdditionaBillApproval = false;
        this.messages = {};
        this.configurations = {};
        this.configurationsDetails = [];
        this.CaseDetailsID = 0;
        this.InvestigationID = 0;
        this.PolicyNumber = "";
        this.VerticalName = "";
        this.InsuredState = "";
        this.ClaimName = "";
        this.ClientID = 0;


        this.BranchID = 0;
        this.ClaimNo = "";
        this.ProcessingPlatformVisible = false;
        this.VirtualInvestigationVisible = false;
        this.BulkCaseUploadVisible = false;
        this.RulingAuditVisible = false;
        this.MediaAuditVisible = false;
        this.MedicalAuditVisible = false;
        this.DesktopAuditVisible = false;
        this.AllocationVisible = false;
        this.NetworkingVisible = false;
        this.ClientBillVisible = false;
        this.VideoConferenceVisible = false;
        this.InvoiceExcelUploadVisible = false;
        this.InternalChatVisible = false;
        this.HistoricalDataVisible = false;
        this.FindingsVisible = false;
        this.BillReconciliationVisible = false;
        this.HardCopyVisible = false;
        this.ClosureVisible = false;
        this.VigilanceVisible = false;
        this.FoPaymentVisible = false;
        this.FoBillingVisible = false;
        this.FoFindingsVisible = false;
        this.commonReportVisible = false;
        this.userReportVisible = false;
        this.auditReportVisible = false;
        this.accountReportVisible = false;
        this.venderBillingVisible = false;
        this.FOBill = 0;
        this.FOPayment = 0;
        this.ClientBill = 0;
        this.ClientPayment = 0;
        this.VendorBill = 0;
        this.VendorPayment = 0;
        this.OutOfTatUser = false;
        this.EmployeeImagePath = "";
        this.UserBucketList = "";
        this.UserBucketListArr = [];
        this.LatLongList = [];
        this.NewVisitLatLong = "";
        this.EmployeeDesignation = "";
    }

    // Getting Custom Headers For Get
    GetCustomHeadersForGet() {
        const customHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET'
        };
        return customHeaders;
    }

    // Getting Custom Headers For Post
    GetCustomHeadersForPost() {
        const customHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST'
        };
        return customHeaders;
    }

    // Getting Custom Headers For Delete
    GetCustomHeadersForDelete() {
        const customHeaders = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'DELETE'
        };
        return customHeaders;
    }

    // Getting Custom Headers For Post form data
    GetCustomHeadersForPostFormData() {
        const customHeaders = {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST'
        };
        return customHeaders;
    }

    // Setting new boolean value to subject
    setSubjectBooleanValue(param: boolean) {
        this.subject.next(param);
    }

    // Showing messages using toastr and type of message
    ShowToastrMessage(type: string, message: string) {
        if (type === 'success') {
            this.toastrService.success(this.messages["msg_Success"]);
        } else if (type === 'warning') {
            this.toastrService.warning(message == "delete" ? this.messages["msg_Delete"] : message);
        } else if (type === 'error') {
            this.toastrService.error(this.messages["msg_Exception"]);
        }
    }
}
