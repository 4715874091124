import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GlobalService } from 'app/shared/global.service';

@Injectable({
   providedIn: 'root'
})
export class DashboardV1Service {

   constructor(private http: HttpClient, public router: Router, private globals: GlobalService) { }


   // Getting Doctor Wisre Patient Count
   GetUserDashboardKPIlist(UserID, ApplicableFor, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetUserDashboardKPIlist?UserID=' + UserID +
         '&ApplicableFor=' + ApplicableFor + '&ClientID=' + ClientID +
         '&LocationID=' + LocationID, { headers: customHeaders })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting Doctor Wisre Patient Count
   UserCollectionDetails(UserName, SelectedDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/UserCollectionDetails?userName=' + UserName +
         '&selectedDate=' + SelectedDate + '&clientID=' + ClientID + '&locationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   GetKPIDetailsResult() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetKPIDetailsResult', { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   GetDashBoardAllOutOfTATList(UserID, EmployeeID, EmployeeDesignation, UserName, fromdate, todate, clientSuperAdmin) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetDashBoardAllOutOfTATList?UserID=' + UserID +
         '&EmployeeID=' + EmployeeID + '&EmployeeDesignation=' + EmployeeDesignation + '&UserName=' + UserName + '&fromdate=' + fromdate +
         '&todate=' + todate + '&clientSuperAdmin=' + clientSuperAdmin, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // getting Case Counter Collection
   GetCaseCounter(OpIpFlag, ResultType, FromDate, ToDate, CompanyID, LocationID, UserID, EmployeeID, EmployeeDesignation, clientSuperAdmin) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetCaseCounter?opIpFlag=' + OpIpFlag + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&CompanyID=' + CompanyID +
         '&locationID=' + LocationID + '&UserID=' + UserID +
         '&EmployeeID=' + EmployeeID + '&EmployeeDesignation=' + EmployeeDesignation + '&clientSuperAdmin=' + clientSuperAdmin, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // getting Client wise Collection
   GetClientWiseCounter(OpIpFlag, ResultType, FromDate, ToDate, CompanyID, LocationID, UserID, EmployeeID, EmployeeDesignation, clientSuperAdmin) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetClientWiseCounter?opIpFlag=' + OpIpFlag + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&CompanyID=' + CompanyID +
         '&locationID=' + LocationID + '&UserID=' + UserID + '&EmployeeID=' + EmployeeID + '&EmployeeDesignation=' + EmployeeDesignation + '&clientSuperAdmin=' + clientSuperAdmin, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting Open and Close Case Counter
   GetOpenandCloseCaseCounter(OpIpFlag, ResultType, FromDate, ToDate, CompanyID, LocationID, UserID, EmployeeDesignation, EmployeeID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetOpenandCloseCaseCounter?opIpFlag=' + OpIpFlag + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&CompanyID=' + CompanyID +
         '&locationID=' + LocationID + '&UserID=' + UserID + '&EmployeeDesignation=' + EmployeeDesignation + '&EmployeeID=' + EmployeeID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting TAT and Outof TAT Counter
   GetTATandOutofTATCounter(OpIpFlag, ResultType, FromDate, ToDate, CompanyID, LocationID, UserID, EmployeeID, EmployeeDesignation, clientSuperAdmin) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetTATandOutofTATCounter?opIpFlag=' + OpIpFlag + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&CompanyID=' + CompanyID +
         '&locationID=' + LocationID + '&UserID=' + UserID + '&EmployeeID=' + EmployeeID + '&EmployeeDesignation=' + EmployeeDesignation + '&clientSuperAdmin=' + clientSuperAdmin, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting State Wise Count
   GetStateWiseCount(OpIpFlag, ZoneName, ResultType, FromDate, ToDate, CompanyID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetStateWiseCount?opIpFlag=' + OpIpFlag + '&ZoneName=' + ZoneName + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&CompanyID=' + CompanyID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting Claim Manager Wise Count
   GetClaimManagerWiseCount(OpIpFlag, ClaimManagerID, ResultType, FromDate, ToDate, CompanyID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetClaimManagerWiseCount?opIpFlag=' + OpIpFlag + '&ClaimManagerID=' + ClaimManagerID + '&resultType=' + ResultType +
         '&fromDate=' + FromDate + '&toDate=' + ToDate + '&CompanyID=' + CompanyID +
         '&locationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting Claim Manager Wise Case Analysis
   GetClaimManagerWiseCaseAnalysis(fromdate, todate, CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetClaimManagerWiseCaseAnalysis?fromdate=' + fromdate + '&todate=' + todate + '&CompanyID=' + CompanyID + '&LocationID=' + LocationID,
         { headers: customHeaders })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   GetAreaManagerWiseCaseAnalysis(fromdate, todate, CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetAreaManagerWiseCaseAnalysis?fromdate=' + fromdate + '&todate=' + todate + '&CompanyID=' + CompanyID + '&LocationID=' + LocationID,
         { headers: customHeaders })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }


   //Getting Client And Claim Wise Case Analysis
   GetClientAndClaimWiseCaseAnalysis(fromdate, todate, CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetClientAndClaimWiseCaseAnalysis?fromdate=' + fromdate + '&todate=' + todate + '&CompanyID=' + CompanyID + '&LocationID=' + LocationID,
         { headers: customHeaders })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting State Wise Case Analysis
   GetStateWiseCaseAnalysis(fromdate, todate, CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetStateWiseCaseAnalysis?fromdate=' + fromdate + '&todate=' + todate + '&CompanyID=' + CompanyID + '&LocationID=' + LocationID,
         { headers: customHeaders })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   GetNonAllocatedFOLocation(fromDate, toDate, CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetNonAllocatedFOLocation?fromDate=' + fromDate +
         '&toDate=' + toDate + '&CompanyID=' + CompanyID + '&LocationID=' + LocationID, { headers: customHeaders })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   GetNetWorkingBucketCases(fromDate, toDate, CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetNetWorkingBucketCases?fromDate=' + fromDate +
         '&toDate=' + toDate + '&CompanyID=' + CompanyID + '&LocationID=' + LocationID, { headers: customHeaders })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // getting Case detail details using counters 
   GetDashboardShowCaseDetails(KPIName, ResultType, fromDate, toDate, CompanyID, LocationID, UserID, EmployeeID, EmployeeDesignation, name, clientSuperAdmin, CaseType) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetDashboardShowCaseDetails?KPIName=' + KPIName + '&resultType=' + ResultType +
         '&fromDate=' + fromDate + '&toDate=' + toDate + '&CompanyID=' + CompanyID +
         '&LocationID=' + LocationID + '&UserID=' + UserID +
         '&EmployeeID=' + EmployeeID + '&EmployeeDesignation=' + EmployeeDesignation + '&name=' + name + '&clientSuperAdmin=' + clientSuperAdmin + '&CaseType=' + CaseType, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // getting claim manager wise details show using counters 
   GetDashboardShowClaimManagerWiseCaseDetails(KPIName, FieldName, fromDate, toDate, CompanyID, LocationID, UserID, EmployeeID, name) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetDashboardShowClaimManagerWiseCaseDetails?KPIName=' + KPIName + '&FieldName=' + FieldName +
         '&fromDate=' + fromDate + '&toDate=' + toDate + '&CompanyID=' + CompanyID +
         '&LocationID=' + LocationID + '&UserID=' + UserID + '&EmployeeID=' + EmployeeID + '&name=' + name, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // getting area manager wise details show using counters 
   GetDashboardShowAreaManagerWiseCaseDetails(KPIName, FieldName, fromDate, toDate, CompanyID, LocationID, UserID, EmployeeID, name) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetDashboardShowAreaManagerWiseCaseDetails?KPIName=' + KPIName + '&FieldName=' + FieldName +
         '&fromDate=' + fromDate + '&toDate=' + toDate + '&CompanyID=' + CompanyID +
         '&LocationID=' + LocationID + '&UserID=' + UserID + '&EmployeeID=' + EmployeeID + '&name=' + name, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   GetVIAuditCaseCounter(ResultType, EmployeeID, CompanyID, LocationID, TodayDate) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetVIAuditCaseCounter?ResultType=' + ResultType + '&EmployeeID=' + EmployeeID +
         '&CompanyID=' + CompanyID + '&LocationID=' + LocationID + '&TodayDate=' + TodayDate, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   getUser(CompanyID, ComponentID, LocationID, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'internalchat/GetAll?CompanyID=' + CompanyID
         + '&ComponentID=' + ComponentID + '&LocationID=' + LocationID + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   getInternalChatById(id) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'InternalChat/Get?id=' + id, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   saveUser(internalchat) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'internalchat', internalchat, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   DeactivateInternalChat(InternalChatID, InternalChatDetailsID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.delete<any>(environment.apiUrl + 'InternalChat/Delete?InternalChatID=' + InternalChatID
         + '&InternalChatDetailsID=' + InternalChatDetailsID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   GetVideoAnalysis(fromDate, toDate, CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetVideoAnalysis?fromDate=' + fromDate + '&toDate=' + toDate + '&CompanyID=' + CompanyID +
         '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   GetDashboardShowVideoDetails(KPIName, fromDate, toDate, CompanyID, LocationID, UserID, CaseDetailsID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetDashboardShowVideoDetails?KPIName=' + KPIName +
         '&fromDate=' + fromDate + '&toDate=' + toDate + '&CompanyID=' + CompanyID +
         '&LocationID=' + LocationID + '&UserID=' + UserID + '&CaseDetailsID=' + CaseDetailsID, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting Allocation details case wise
   GetDashboardAllocationDashboard(fromDate, toDate, EmployeeID, CompanyID, LocationID, SelectedState, SelectedDistrict) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'DashBord/GetDashboardAllocationDashboard?fromDate=' + fromDate +
         '&toDate=' + toDate + '&EmployeeID=' + EmployeeID + '&CompanyID=' + CompanyID +
         '&LocationID=' + LocationID + '&SelectedState=' + SelectedState + '&SelectedDistrict=' + SelectedDistrict, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
}
